<template>
   <section class="user-dashboard">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title">
                        <h2>User Dashboard!</h2> 
                    </div>
                    <h2>ID: {{ user.id }}</h2> 
                    <h2>Name: {{ user.name }}</h2> 
                    <h2>Email: {{ email }}</h2> 
                    <h2>Mobile: {{ mobile_number }}</h2> 
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from "axios";
import store from "@/store";
import api from '@/services/api';

export default {
    data() {
        return {
            user: store.getters.user,
            email:"",
            mobile_number:"",
        };
    },
    mounted() {
        let app = this;
        app.getUser(store.getters.user.id);
    },
    methods:{
        getUser(user_id){
            let app = this;
            axios.get(api.getUrl('/user/'+user_id)).then(function (response) {
                if(response.data.success == true){
                    app.email = response.data.data.email;
                    app.mobile_number = response.data.data.mobile_number;
                }
            });
        },
    },
}
</script>
